<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-card class="mx-auto" max-width="600" outlined>
          <v-card-title class="card-title">
            <img alt="Logo" src="../assets/stru_logo.png" class="mr-4"/>
            <div class="text-heading-6">Авторизация<br>пользователя</div>
          </v-card-title>
          <v-card-text>
            <v-alert outlined :type="status" class="mt-4">{{ message }}</v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Auth',
  props: {
    authtoken: String
  },
  data: function () {
    return {
      status: 'info',
      message: 'Выполняется проверка...'
    }
  },
  methods: {
  },
  async mounted() {
    try {
      await axios.get(`${process.env.VUE_APP_API_BASE_URL}/auth/${this.authtoken}`)
      this.status = 'success'
      this.message = 'Авторизация пройдена успешно'
      localStorage.setItem('authtoken', this.authtoken)
    } catch (error) {
      this.status = 'error'
      this.message = error.response.data
      localStorage.removeItem('authtoken')
    }
  }
}
</script>

<style lang="scss">
</style>
